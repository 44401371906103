import { doc, onSnapshot } from 'firebase/firestore';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { Company } from 'cosdb-types';

export const subscribeToCompany = (
  { companyId }: { companyId: string },
  callback: (company: Company) => void,
) => {
  const q = doc(firestore, 'c', companyId);

  return onSnapshot(
    q,
    (querySnapshot) =>
      callback({ ...querySnapshot.data(), id: querySnapshot.id } as Company),
    (e) =>
      logEvent(AnalyticsEvent.SubscribeToCompanyError, { message: e.message }),
  );
};
