import { collection, onSnapshot } from 'firebase/firestore';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { Order } from 'cosdb-types';

export const subscribeToOrdersByCompanyId = (
  { companyId }: { companyId: string },
  callback: (orders: Order[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'orders');

  return onSnapshot(
    q,
    (querySnapshot) => {
      const r: Order[] = [];
      querySnapshot.forEach((doc) =>
        r.push({ ...doc.data(), id: doc.id } as Order),
      );

      callback(r);
    },
    (e) =>
      logEvent(AnalyticsEvent.SubscribeToOrdersError, { message: e.message }),
  );
};
