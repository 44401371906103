import { collection, onSnapshot } from 'firebase/firestore';
import { AnalyticsEvent, firestore, logEvent } from '@/firebase';
import { Brand } from 'cosdb-types';

export const subscribeToBrands = (
  { companyId }: { companyId: string },
  callback: (brands: Brand[]) => void,
) => {
  const q = collection(firestore, 'c', companyId, 'brands');

  return onSnapshot(
    q,
    (querySnapshot) => {
      const r: Brand[] = [];
      querySnapshot.forEach((doc) =>
        r.push({ ...doc.data(), id: doc.id } as Brand),
      );

      callback(r);
    },
    (e) =>
      logEvent(AnalyticsEvent.SubscribeToBrandsError, { message: e.message }),
  );
};
