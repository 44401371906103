"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatus = exports.AppointmentStatus = exports.Mode = exports.RequestStatus = void 0;
var RequestStatus;
(function (RequestStatus) {
    RequestStatus["IN_PROGRESS"] = "IN_PROGRESS";
    RequestStatus["IS_DONE"] = "IS_DONE";
    RequestStatus["DEFAULT"] = "DEFAULT";
})(RequestStatus = exports.RequestStatus || (exports.RequestStatus = {}));
var Mode;
(function (Mode) {
    Mode["REGULAR"] = "REGULAR";
    Mode["SET_BREAK"] = "SET_BREAK";
    Mode["SET_DAYS"] = "SET_DAYS";
})(Mode = exports.Mode || (exports.Mode = {}));
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["PENDING"] = "PENDING";
    AppointmentStatus["CANCELLED"] = "CANCELLED";
    AppointmentStatus["DONE"] = "DONE";
})(AppointmentStatus = exports.AppointmentStatus || (exports.AppointmentStatus = {}));
var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["NOT_PAID"] = "NOT_PAID";
    PaymentStatus["PAID_BY_CASH"] = "PAID_BY_CASH";
    PaymentStatus["PAID_BY_CARD"] = "PAID_BY_CARD";
})(PaymentStatus = exports.PaymentStatus || (exports.PaymentStatus = {}));
